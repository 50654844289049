import React, { Component } from 'react';
import MainView from "./MainView";

export default class Home extends Component {
  render() {
    return (
      <div>
        <MainView {...this.props}/>
      </div>
    );
  }
}
