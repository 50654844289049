import React, { Component } from 'react';


class Frame extends Component {
  render() {
     const { src, height, width } = this.props

    return <div style={{height}}> <iframe src={src} height={'100%'} width={width} /> </div>
  }
}

export default Frame;
