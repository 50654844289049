import React from "react";
import SlideShow from '../SlideShow'

class AcquisitionCost extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      screens:[
        {name: '', topMargin:'0px', title:'All Vendors Breakdown', type: 'url', delay: 10000, url:`https://search-contacts-cokmdmwyg36lqjo65vqw4y4wty.us-east-1.es.amazonaws.com/_plugin/kibana/app/kibana#/dashboard/62915f80-2b16-11e9-8552-89efb613260c?embed=true&_g=(refreshInterval%3A(display%3AOff%2Cpause%3A!f%2Cvalue%3A0)%2Ctime%3A(from%3Anow%2Fy%2Cmode%3Aquick%2Cto%3Anow%2Fy))`},
        {name: '', topMargin:'0px', title:'Contactability Acquisition Costs', type: 'url', delay: 10000, url:`https://search-contacts-cokmdmwyg36lqjo65vqw4y4wty.us-east-1.es.amazonaws.com/_plugin/kibana/app/kibana#/dashboard/336baff0-2b0f-11e9-8552-89efb613260c?embed=true&_g=(refreshInterval%3A(display%3AOff%2Cpause%3A!f%2Cvalue%3A0)%2Ctime%3A(from%3Anow%2Fy%2Cmode%3Aquick%2Cto%3Anow%2Fy))`},
        {name: '', topMargin:'0px', title:'Datalot Acquisition Costs', delay: 10000, type: 'url', url:`https://search-contacts-cokmdmwyg36lqjo65vqw4y4wty.us-east-1.es.amazonaws.com/_plugin/kibana/app/kibana#/dashboard/5b1000b0-2b0f-11e9-8863-7787017f8626?embed=true&_g=(refreshInterval%3A(display%3AOff%2Cpause%3A!f%2Cvalue%3A0)%2Ctime%3A(from%3Anow%2Fy%2Cmode%3Aquick%2Cto%3Anow%2Fy))`},
        {name: '', topMargin:'0px', title:'Infinix Acquisition Costs', delay: 10000, type: 'url', url: `https://search-contacts-cokmdmwyg36lqjo65vqw4y4wty.us-east-1.es.amazonaws.com/_plugin/kibana/app/kibana#/dashboard/b9c4ff70-2b0f-11e9-8863-7787017f8626?embed=true&_g=(refreshInterval%3A(display%3AOff%2Cpause%3A!f%2Cvalue%3A0)%2Ctime%3A(from%3Anow%2Fy%2Cmode%3Aquick%2Cto%3Anow%2Fy))`},
        {name: '', topMargin:'50px', title:'NextGen Acquisition Costs',delay: 10000, type: 'url', url: `https://search-contacts-cokmdmwyg36lqjo65vqw4y4wty.us-east-1.es.amazonaws.com/_plugin/kibana/app/kibana#/dashboard/c6ffba30-2b10-11e9-8863-7787017f8626?embed=true&_g=(refreshInterval%3A(display%3AOff%2Cpause%3A!f%2Cvalue%3A0)%2Ctime%3A(from%3Anow%2Fy%2Cmode%3Aquick%2Cto%3Anow%2Fy))`},
        {name: '', topMargin:'50px', title:'Norvax Acquisition Costs',delay: 10000, type: 'url', url:`https://search-contacts-cokmdmwyg36lqjo65vqw4y4wty.us-east-1.es.amazonaws.com/_plugin/kibana/app/kibana#/dashboard/f15918d0-2b10-11e9-8552-89efb613260c?embed=true&_g=(refreshInterval%3A(display%3AOff%2Cpause%3A!f%2Cvalue%3A0)%2Ctime%3A(from%3Anow%2Fy%2Cmode%3Aquick%2Cto%3Anow%2Fy))`},
        {name: '', topMargin:'50px', title:'QuoteWizard Acquisition Costs', delay: 10000, type: 'url', url:`https://search-contacts-cokmdmwyg36lqjo65vqw4y4wty.us-east-1.es.amazonaws.com/_plugin/kibana/app/kibana#/dashboard/3a1d54f0-2b11-11e9-8863-7787017f8626?embed=true&_g=(refreshInterval%3A(display%3AOff%2Cpause%3A!f%2Cvalue%3A0)%2Ctime%3A(from%3Anow%2Fy%2Cmode%3Aquick%2Cto%3Anow%2Fy))`},
        {name: '', topMargin:'50px', title:'Sonic Leads Acquisition Costs', delay: 10000, type: 'url', url:`https://search-contacts-cokmdmwyg36lqjo65vqw4y4wty.us-east-1.es.amazonaws.com/_plugin/kibana/app/kibana#/dashboard/75bc64b0-2b11-11e9-8552-89efb613260c?embed=true&_g=(refreshInterval%3A(display%3AOff%2Cpause%3A!f%2Cvalue%3A0)%2Ctime%3A(from%3Anow%2Fy%2Cmode%3Aquick%2Cto%3Anow%2Fy))`},
      ]
    }
  }
  componentDidMount() {
  
  }
  
  render() {
    const { screens } = this.state
    return ( 
      <SlideShow screens={screens} />
      )
  }
}




export default AcquisitionCost;