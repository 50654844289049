import React from "react";
import { Container, Row, Col } from 'react-materialize';
import { Storage } from 'aws-amplify';
import Fade from '../../lib/Fade'
import Frame from '../../lib/Frame'
import CustomDataTable from './CustomDataTable'
import logo from '../../assets/img/usha-logo.png';


class MainView extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loaded:false,
      activeIndex: 0,
      activeTitle: 'Loading....',
    }
  }
  componentDidMount() {
   // this.props.commonStore.toggleShowHeader()
    this.makeBoards()
    this.timer = setInterval(this.moveNext, 10000)
    this.setRefresh()
    window.addEventListener('resize', this.updateWindowDimensions);
    this.updateWindowDimensions()
  }
  componentWillUnmount() {
    clearInterval(this.timer)
    clearInterval(this.refresh)
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions = () => {
    // document.removeEventListener('keydown', this.onKeydown);

    const height = window.innerHeight;
    const width = window.innerWidth;

    this.setState({ height, width })

  }
  setRefresh = () => {
    clearInterval(this.refresh)
    this.refresh = setInterval(this.refreshData, 600000)
  }
  refreshData = async() => {
    const { screens } = this.props
    for (var i = 0; i < screens.length; i++) {
      if (screens[i].type === 'data'){
        await this.downloadCustomData(screens[i].name)
      }
    }
  }
  setMoveNext = (interval) => {
    clearInterval(this.timer)
    this.timer = setInterval(this.moveNext, interval)
  }
  downloadCustomData = async (name) => {
    console.log(name)
    const { ownerID } = this.props
    return Storage.get(`networks/${ownerID}/slideshow/data/${name}`)
        .then (collectionURL => {

                return fetch(collectionURL)
                .then((result) => {
                  return result.json()
                })
                .then((data) => {
                  const dataSlice = data.slice(0, 20)
                  console.log(dataSlice)
                  this.setState({[name]:dataSlice})
                })
    }).catch(err => console.log(err));
  }

  makeBoards = async() => {
    const { screens } = this.props

    for (var i = 0; i < screens.length; i++) {
      
      const showKey = `key${i.toString()}`
      if (i < 1){
        this.setState({[showKey]:true})
      }else{
        this.setState({[showKey]:false})
      }

      if(screens[i].type === 'data'){
         await this.downloadCustomData(screens[i].name)
      //  this.props.authStore.getCustomDataObject('ushealth_orlando', screens[i].name, screens[i].type);
      }
    }
    this.setState({activeTitle: screens[0].title})
  }
  showBoards = () => {
    const { screens } = this.props
    const { height, width } = this.state
    const boardsToShow = []
    for (var i = 0; i < screens.length; i++) {
      
      const boardKey = `board${i.toString()}`
      const show = this.state[`key${i.toString()}`]

      let board
      if (screens[i].type === 'url'){
          board = (
            <div key={boardKey} style={{position:'absolute', marginTop:screens[i].topMargin}}>
           
            <Fade show={show}> 
             <Frame 
            src={screens[i].url}
            width={width}
            height={height}
            /></Fade>
            
            </div>
          )  
      }else if (screens[i].type === 'data'){

       // const rawData = this.props.commonStore.customData[screens[i].name]

        /*if (!rawData){
          continue
        }*/
        let data = []
        if(screens[i].name in this.state){
          console.log(this.state[screens[i].name])
           data = this.state[screens[i].name]
        }
 
        console.log(data)

        let columns
        if (data.length > 0){
            columns = Object.keys(data[0])
            columns = columns.map(column => {
              return {dataField:column.replace(/[\W_]+/g," "), text:column,  headerStyle: { textAlign: 'center' }}
            })
            
           const formattedData = data.map(item => {
              const newItem = {}
              for (var key in item) {
                newItem[key.replace(/[\W_]+/g," ")] = item[key]
              }
              return newItem
            })


           // let width = '100%'

            console.log(formattedData)
            console.log(columns)
//style={{textAlign:'center', float:'none'}}
            board = (

       

          
              <Container key={boardKey} style={{position:'absolute', marginTop:screens[i].topMargin, width: '100%'}}>
              <Fade show={show}>
                <div style={{backgroundColor:'#FFFFFF'}}>
                
                <hr style={{height:'2px', border:'none', color:'#004B8D', backgroundColor: '#004B8D'}}/>
        
                <CustomDataTable
                  data={formattedData}
                  columns={columns}
                  name={screens[i].name}
                />
                </div>
              </Fade>
              </Container>
       
       
            )  
        }else{
            continue;
        }
      }else if (screens[i].type === 'image'){

        board = (
          <div key={boardKey} style={{position:'absolute', textAlign:'center', width:'100%', marginTop:screens[i].topMargin, paddingLeft:'20px'}}>
          <Fade show={show}>
            <div style={{backgroundColor:'#FFFFFF'}}>
            <img src={screens[i].url} style={{width:'50%', height:'50%'}}/>;
            </div>
          </Fade>
          </div>
        )  
      }else{
        continue;
      }

      boardsToShow.push(board)
    }  

    return boardsToShow
  }
  moveNext = () => {
    const { screens } = this.props
    const { activeIndex } = this.state
    let newIndex
    if (activeIndex == (screens.length - 1)){
      newIndex = 0
    }else {
      newIndex = activeIndex + 1 
    }
    const show = `key${newIndex.toString()}`
    const hide = `key${activeIndex.toString()}`

   this.setState({[show]: true, [hide]: false, activeTitle: screens[newIndex].title, activeIndex: newIndex})
   this.setMoveNext(screens[newIndex].delay)
  }
  render() {
    const { logoRight } = this.props
    const { activeTitle } = this.state
    console.log(activeTitle)

    return (
      <div>
      <div>
    
      { this.showBoards() }
  
      </div>
        <div style={{
        textAlign:'center', 
        width:'100%', 
        height:'90px', 
        margin: '0 auto', 
        position: 'absolute', 
        color:'#000000',
        backgroundColor:'#FFFFFF'
        }}>
              <div style={{textAlign:'left'}}>
                <img src={logo} style={{width:'250px', height:'90px'}} alt="usha-logo" />
              </div>
        </div>
        <div style={{textAlign:'center', width:'100%', position: 'absolute'}}>
        <h2 style={{color:'#004B8D'}} ><b>{activeTitle}</b></h2>
        </div>
        <div style={{textAlign:'right', width:'100%', position: 'absolute'}}>
        <img src={logoRight} style={{width:'80px', height:'80px', marginRight:'20px'}} alt="logo" />
        </div>
      </div>
    );
  }
}




export default MainView;
