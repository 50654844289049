import React, { Component } from 'react';
import { HashRouter, Switch, Route } from "react-router-dom"
import { Auth } from 'aws-amplify';
import { ProgressBar } from 'react-materialize'
import Home from './components/Home'
import Powerboard from './components/Powerboard';
import AquisitionCost from './components/AcquisitionCost';

export default class Front extends Component {
  state = {
    admin: false,
    loading: true,
    height: 0,
  };
  componentDidMount() {
    Auth.currentUserInfo()
    .then(data => {
        console.log(data)
        const { id: userID, attributes } = data
   
        const { 
            ['custom:admin']: adminString, 
            ['custom:ownerID']: ownerID,
       } = attributes

       console.log('attributes')
       console.log(attributes)
       
        let admin = false 
        if(adminString){
          console.log('adminString')
          console.log(adminString)
          admin = parseInt(adminString) > 0
        }

        console.log('admin')
        console.log(admin)

        this.setState({admin, ownerID, loading: false})
      })
  }

  render() {
    const { loading, admin, ownerID } = this.state
    if(loading){
      return <ProgressBar />
    }

    return (
      <HashRouter>
      <Switch>
        <Route exact path="/" component={Home} />
       {/* <Route exact path="/powerboard/:id" component={Powerboard} />*/}
       <Route exact path="/powerboard" render={(props) => <Powerboard {...props} ownerID={ownerID} />} />
       
       {admin ? <Route exact path="/acquisition" component={AquisitionCost} /> : null}
      </Switch>
      </HashRouter>
    );
  
   
  }
}
