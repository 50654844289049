import React from "react";
import SlideShow from '../SlideShow'

class Powerboard extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      clients: {
        '6557461f-1df9-4a37-91bc-8fb5dddba2ee':{
          logo: 'https://s3.amazonaws.com/data.sonicwebdev.com/logos/6557461f-1df9-4a37-91bc-8fb5dddba2ee.png',
          screens:[
            {name: '', topMargin:'0px', title:'Total Actions Today', delay: 25000, type: 'url', url: `https://dorne.sonicgraphs.com:5601/app/kibana#/dashboard/9d29a7a0-c045-11e9-b9b5-e3f19f1c6d96?embed=true&_g=(refreshInterval:(pause:!f,value:10000),time:(from:now%2Fd,to:now%2Fd))&_a=(description:'First+Slide+Dorne+Region+Powerboard',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),panels:!((embeddableConfig:(),gridData:(h:18,i:'1',w:48,x:0,y:0),id:'6a44c900-c045-11e9-b9b5-e3f19f1c6d96',panelIndex:'1',title:'TOTAL+ACTIONS',type:visualization,version:'7.3.0'),(embeddableConfig:(),gridData:(h:14,i:'2',w:48,x:0,y:18),id:'15f85230-c046-11e9-b9b5-e3f19f1c6d96',panelIndex:'2',title:'AGENT+WITH+MOST+RECENT+SOLD+LEAD',type:visualization,version:'7.3.0')),query:(language:kuery,query:''),timeRestore:!t,title:'Powerboard+1',viewMode:view)`},
            {name: '', topMargin:'0px', title:'Total Sold Lead by User', type: 'url', delay: 10000, url: `https://dorne.sonicgraphs.com:5601/app/kibana#/dashboard/521bdec0-c047-11e9-b9b5-e3f19f1c6d96?embed=true&_g=(refreshInterval:(pause:!f,value:10000),time:(from:now%2Fd,to:now%2Fd))&_a=(description:'Second+Slide+Dorne+Region+Powerboard',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),panels:!((embeddableConfig:(),gridData:(h:18,i:'1',w:48,x:0,y:0),id:'1ebffb60-c047-11e9-b9b5-e3f19f1c6d96',panelIndex:'1',type:visualization,version:'7.3.0'),(embeddableConfig:(),gridData:(h:14,i:'2',w:48,x:0,y:18),id:'15f85230-c046-11e9-b9b5-e3f19f1c6d96',panelIndex:'2',title:'AGENT+WITH+MOST+RECENT+SOLD+LEAD',type:visualization,version:'7.3.0')),query:(language:kuery,query:''),timeRestore:!t,title:'Powerboard+2',viewMode:view)`},
            {name: '', topMargin:'0px', title:'Best Lead Vendors Today', delay: 10000, type: 'url', url: `https://dorne.sonicgraphs.com:5601/app/kibana#/dashboard/53a680f0-c048-11e9-b9b5-e3f19f1c6d96?embed=true&_g=(refreshInterval:(pause:!f,value:10000),time:(from:now%2Fd,to:now%2Fd))&_a=(description:'Third+Slide+Dorne+Region+Powerboard',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),panels:!((embeddableConfig:(),gridData:(h:18,i:'1',w:48,x:0,y:0),id:'0fea6a70-c048-11e9-b9b5-e3f19f1c6d96',panelIndex:'1',title:'TOP+LEAD+VENDORS',type:visualization,version:'7.3.0'),(embeddableConfig:(),gridData:(h:14,i:'2',w:48,x:0,y:18),id:'15f85230-c046-11e9-b9b5-e3f19f1c6d96',panelIndex:'2',title:'AGENT+WITH+MOST+RECENT+SOLD+LEAD',type:visualization,version:'7.3.0')),query:(language:kuery,query:''),timeRestore:!t,title:'Powerboard+3',viewMode:view)`},
            {name: '', topMargin:'0px', title:'Top States Today', delay: 10000, type: 'url', url: `https://dorne.sonicgraphs.com:5601/app/kibana#/dashboard/4b53cec0-c049-11e9-b9b5-e3f19f1c6d96?embed=true&_g=(refreshInterval:(pause:!f,value:10000),time:(from:now%2Fd,to:now%2Fd))&_a=(description:'Fourth+Slide+Dorne+Region+Powerboard',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),panels:!((embeddableConfig:(),gridData:(h:18,i:'1',w:48,x:0,y:0),id:'0e4b43f0-c049-11e9-b9b5-e3f19f1c6d96',panelIndex:'1',type:visualization,version:'7.3.0'),(embeddableConfig:(),gridData:(h:14,i:'2',w:48,x:0,y:18),id:'15f85230-c046-11e9-b9b5-e3f19f1c6d96',panelIndex:'2',title:'AGENT+WITH+MOST+RECENT+SOLD+LEAD',type:visualization,version:'7.3.0')),query:(language:kuery,query:''),timeRestore:!t,title:'Powerboard+4',viewMode:view)`},
            {name: '', topMargin:'50px', title:'Most Recent Sold Deal',delay: 10000, type: 'url', url: `https://dorne.sonicgraphs.com:5601/app/kibana#/dashboard/38bb4cf0-c04b-11e9-b9b5-e3f19f1c6d96?embed=true&_g=(refreshInterval:(pause:!f,value:10000),time:(from:now%2Fd,to:now%2Fd))&_a=(description:'Fifth+Slide+Dorne+Region+Powerboard',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),panels:!((embeddableConfig:(),gridData:(h:14,i:'1',w:48,x:0,y:18),id:'15f85230-c046-11e9-b9b5-e3f19f1c6d96',panelIndex:'1',title:'AGENT+WITH+MOST+RECENT+SOLD+LEAD',type:visualization,version:'7.3.0'),(embeddableConfig:(),gridData:(h:9,i:'2',w:24,x:0,y:0),id:bef2ce80-c049-11e9-b9b5-e3f19f1c6d96,panelIndex:'2',type:visualization,version:'7.3.0'),(embeddableConfig:(),gridData:(h:9,i:'3',w:24,x:0,y:9),id:f470ce90-c049-11e9-b9b5-e3f19f1c6d96,panelIndex:'3',type:visualization,version:'7.3.0'),(embeddableConfig:(mapCenter:!(34.76897348330494,-95.47325134277344),mapZoom:4),gridData:(h:18,i:'4',w:24,x:24,y:0),id:'96ece050-c04a-11e9-b9b5-e3f19f1c6d96',panelIndex:'4',type:visualization,version:'7.3.0')),query:(language:kuery,query:''),timeRestore:!t,title:'Powerboard+5',viewMode:view)`},
            {name: '', topMargin:'50px', title:'Most Recent Sold Policies',delay: 10000, type: 'url', url: `https://dorne.sonicgraphs.com:5601/app/kibana#/dashboard/6739f5d0-c04c-11e9-b9b5-e3f19f1c6d96?embed=true&_g=(refreshInterval:(pause:!f,value:10000),time:(from:now%2Fd,to:now%2Fd))&_a=(description:'Sixth+Slide+Dorne+Region+Powerboard',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),panels:!((embeddableConfig:(),gridData:(h:18,i:'1',w:24,x:0,y:0),id:d84ff630-c04b-11e9-b9b5-e3f19f1c6d96,panelIndex:'1',type:visualization,version:'7.3.0'),(embeddableConfig:(),gridData:(h:14,i:'2',w:48,x:0,y:18),id:'15f85230-c046-11e9-b9b5-e3f19f1c6d96',panelIndex:'2',title:'AGENT+WITH+MOST+RECENT+SOLD+LEAD',type:visualization,version:'7.3.0'),(embeddableConfig:(),gridData:(h:18,i:'3',w:24,x:24,y:0),id:'316daeb0-c04c-11e9-b9b5-e3f19f1c6d96',panelIndex:'3',type:visualization,version:'7.3.0')),query:(language:kuery,query:''),timeRestore:!t,title:'Powerboard+6',viewMode:view)`},
            {name: '', topMargin:'50px', title:'Total Sold Today', delay: 10000, type: 'url', url: `https://dorne.sonicgraphs.com:5601/app/kibana#/dashboard/07278d20-c050-11e9-b9b5-e3f19f1c6d96?embed=true&_g=(refreshInterval:(pause:!f,value:10000),time:(from:now%2Fd,to:now%2Fd))&_a=(description:'Seventh+Slide+Dorne+Region+Powerboard',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),panels:!((embeddableConfig:(),gridData:(h:57,i:'1',w:48,x:0,y:0),id:b75a5250-c04f-11e9-b9b5-e3f19f1c6d96,panelIndex:'1',type:visualization,version:'7.3.0')),query:(language:kuery,query:''),timeRestore:!t,title:'Powerboard+7',viewMode:view)`},
            {name: 'FTAThisWeekStandings.json', topMargin:'100px', title:'FTA Standings', delay: 8000, type: 'data'},
            {name: 'FSLThisWeekStandings.json', topMargin:'100px', title:'FSL Standings', delay: 8000, type: 'data'},
            {name: 'SatThisWeekStandings.json', topMargin:'100px', title:'Satellite Standings', delay: 8000, type: 'data'},
            {name: 'DivThisWeekStandings.json', topMargin:'100px', title:'Divisional Standings', delay: 8000, type: 'data'},
            {name: 'RegThisWeekStandings.json', topMargin:'100px', title:'Regional Standings', delay: 8000, type: 'data'},
            {name: 'PPThisWeekStandings.json', topMargin:'100px', title:'USHA Production Standings', delay: 8000, type: 'data'},
            {name: 'milestones.jpg', topMargin:'100px', url:'https://s3.amazonaws.com/data.sonicwebdev.com/ushealth_orlando/milestones.jpg', title:'', delay: 8000, type: 'image'}
          ]},
         '45a1e5a5-678a-4045-aa2f-25211aab6f2d':{
          logo: 'https://s3.amazonaws.com/data.sonicwebdev.com/logos/45a1e5a5-678a-4045-aa2f-25211aab6f2d.png', 
          screens:[
          {name: '', topMargin:'50px', title:'Total Actions Today', delay: 25000, type: 'url', url: `https://yecina.sonicgraphs.com:5601/app/kibana#/dashboard/eae25ef0-8c7f-11e9-ad4b-950856084762?embed=true&_g=(filters:!(),refreshInterval:(pause:!f,value:30000),time:(from:now%2Fd,to:now%2Fd))&_a=(description:'Total+Agent+Actions',filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'1555cf40-856f-11e9-8837-673c4c48f13a',key:ownerID.keyword,negate:!f,params:(query:'45a1e5a5-678a-4045-aa2f-25211aab6f2d'),type:phrase,value:'45a1e5a5-678a-4045-aa2f-25211aab6f2d'),query:(match:(ownerID.keyword:(query:'45a1e5a5-678a-4045-aa2f-25211aab6f2d',type:phrase))))),fullScreenMode:!f,options:(darkTheme:!f,hidePanelTitles:!f,useMargins:!f),panels:!((embeddableConfig:(),gridData:(h:23,i:'1',w:48,x:0,y:0),id:d53fe7b0-860e-11e9-ad4b-950856084762,panelIndex:'1',title:'AGENTS+WITH+THE+MOST+CALLS',type:visualization,version:'7.3.0'),(embeddableConfig:(mapCenter:!(36.03133177633189,-95.84472656250001),mapZoom:4),gridData:(h:24,i:'5',w:48,x:0,y:23),id:fe0c69f0-8d4f-11e9-ad4b-950856084762,panelIndex:'5',title:'MOST+ACTIVITY+BY+STATE',type:visualization,version:'7.3.0')),query:(language:lucene,query:''),timeRestore:!t,title:'Shawn+Yecina+-+1',viewMode:view)`},
          {name: '', topMargin:'50px', title:'Total Sold Last 7 Days', type: 'url', delay: 10000, url: `https://yecina.sonicgraphs.com:5601/app/kibana#/dashboard/979205f0-8c81-11e9-ad4b-950856084762?embed=true&_g=(filters:!(),refreshInterval:(pause:!f,value:30000),time:(from:now-7d,to:now))&_a=(description:'Total+Sold+Leads+Last+7+Days',filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'1555cf40-856f-11e9-8837-673c4c48f13a',key:ownerID.keyword,negate:!f,params:(query:'45a1e5a5-678a-4045-aa2f-25211aab6f2d',type:phrase),type:phrase,value:'45a1e5a5-678a-4045-aa2f-25211aab6f2d'),query:(match:(ownerID.keyword:(query:'45a1e5a5-678a-4045-aa2f-25211aab6f2d',type:phrase))))),fullScreenMode:!f,options:(darkTheme:!f,hidePanelTitles:!f,useMargins:!f),panels:!((embeddableConfig:(),gridData:(h:23,i:'1',w:48,x:0,y:0),id:'73467d20-8c81-11e9-8837-673c4c48f13a',panelIndex:'1',title:'TOTAL+SOLD+LEADS+LAST+7+DAYS',type:visualization,version:'7.3.0'),(embeddableConfig:(),gridData:(h:24,i:'2',w:48,x:0,y:23),id:f208db40-8c80-11e9-8837-673c4c48f13a,panelIndex:'2',type:visualization,version:'7.3.0')),query:(language:lucene,query:''),timeRestore:!t,title:'Shawn+Yecina+-+2',viewMode:view)`},
          {name: '', topMargin:'50px', title:'Best Lead Vendors', delay: 10000, type: 'url', url: `https://yecina.sonicgraphs.com:5601/app/kibana#/dashboard/2262c510-8c88-11e9-8837-673c4c48f13a?embed=true&_g=(filters:!(),refreshInterval:(pause:!f,value:30000),time:(from:now-7d,to:now))&_a=(description:'Best+lead+vendors',filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'1555cf40-856f-11e9-8837-673c4c48f13a',key:ownerID.keyword,negate:!f,params:(query:'45a1e5a5-678a-4045-aa2f-25211aab6f2d',type:phrase),type:phrase,value:'45a1e5a5-678a-4045-aa2f-25211aab6f2d'),query:(match:(ownerID.keyword:(query:'45a1e5a5-678a-4045-aa2f-25211aab6f2d',type:phrase))))),fullScreenMode:!f,options:(darkTheme:!f,hidePanelTitles:!f,useMargins:!f),panels:!((embeddableConfig:(),gridData:(h:28,i:'1',w:24,x:0,y:0),id:df5337b0-8c86-11e9-ad4b-950856084762,panelIndex:'1',type:visualization,version:'7.3.0'),(embeddableConfig:(),gridData:(h:23,i:'2',w:48,x:0,y:28),id:f208db40-8c80-11e9-8837-673c4c48f13a,panelIndex:'2',type:visualization,version:'7.3.0'),(embeddableConfig:(vis:(legendOpen:!f)),gridData:(h:28,i:'3',w:24,x:24,y:0),id:'073bb930-8c8e-11e9-ad4b-950856084762',panelIndex:'3',type:visualization,version:'7.3.0')),query:(language:lucene,query:''),timeRestore:!t,title:'Shawn+Yecina+-+3',viewMode:view)`},
          {name: '', topMargin:'50px', title:'Most Recent Sold', delay: 10000, type: 'url', url: `https://yecina.sonicgraphs.com:5601/app/kibana#/dashboard/fa681e50-8c84-11e9-8837-673c4c48f13a?embed=true&_g=(filters:!(),refreshInterval:(pause:!f,value:30000),time:(from:now-7d,to:now))&_a=(description:'Most+recent+sold+state,+zip+code,+and+map+of+states+with+most+agent+activity.+',filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'1555cf40-856f-11e9-8837-673c4c48f13a',key:ownerID.keyword,negate:!f,params:(query:'45a1e5a5-678a-4045-aa2f-25211aab6f2d',type:phrase),type:phrase,value:'45a1e5a5-678a-4045-aa2f-25211aab6f2d'),query:(match:(ownerID.keyword:(query:'45a1e5a5-678a-4045-aa2f-25211aab6f2d',type:phrase))))),fullScreenMode:!f,options:(darkTheme:!f,hidePanelTitles:!f,useMargins:!f),panels:!((embeddableConfig:(mapCenter:!(34.452218472826566,-96.8115234375),mapZoom:4),gridData:(h:28,i:'1',w:26,x:22,y:0),id:'7dc80b50-8c82-11e9-8837-673c4c48f13a',panelIndex:'1',title:'LAST+7+DAYS+SOLD+STATES',type:visualization,version:'7.3.0'),(embeddableConfig:(),gridData:(h:23,i:'2',w:48,x:0,y:28),id:f208db40-8c80-11e9-8837-673c4c48f13a,panelIndex:'2',type:visualization,version:'7.3.0'),(embeddableConfig:(),gridData:(h:11,i:'4',w:14,x:8,y:0),id:'0bbd23a0-8c83-11e9-8837-673c4c48f13a',panelIndex:'4',title:'LAST+SOLD+ZIP+CODE',type:visualization,version:'7.3.0'),(embeddableConfig:(),gridData:(h:11,i:'5',w:8,x:0,y:0),id:'5a86d120-8c83-11e9-ad4b-950856084762',panelIndex:'5',title:'LAST+SOLD+STATE',type:visualization,version:'7.3.0'),(embeddableConfig:(),gridData:(h:17,i:'6',w:22,x:0,y:11),id:e02f0a00-8c8c-11e9-8837-673c4c48f13a,panelIndex:'6',title:'LAST+SOLD+LEAD+SOURCE',type:visualization,version:'7.3.0')),query:(language:lucene,query:''),timeRestore:!t,title:'Shawn+Yecina+-+4',viewMode:view)`},
          {name: 'FTAThisWeekStandings.json', topMargin:'100px', title:'FTA Standings', delay: 8000, type: 'data'},
          {name: 'FSLThisWeekStandings.json', topMargin:'100px', title:'FSL Standings', delay: 8000, type: 'data'},
          {name: 'SatThisWeekStandings.json', topMargin:'100px', title:'Satellite Standings', delay: 8000, type: 'data'},
          {name: 'DivThisWeekStandings.json', topMargin:'100px', title:'Divisional Standings', delay: 8000, type: 'data'},
          {name: 'RegThisWeekStandings.json', topMargin:'100px', title:'Regional Standings', delay: 8000, type: 'data'},
          {name: 'PPThisWeekStandings.json', topMargin:'100px', title:'USHA Production Standings', delay: 8000, type: 'data'},
         // {name: 'milestones.jpg', topMargin:'100px', url:'https://s3.amazonaws.com/data.sonicwebdev.com/ushealth_orlando/milestones.jpg', title:'', delay: 8000, type: 'image'}
        ]},
        '8897ae30-973b-4432-a31b-66dbf7dfff0a':{
          logo: 'https://s3.amazonaws.com/data.sonicwebdev.com/logos/8897ae30-973b-4432-a31b-66dbf7dfff0a.png', 
          screens:[
            {name: '', topMargin:'50px', title:'Total Actions Today', delay: 25000, type: 'url', url: `https://guigui.sonicgraphs.com:5601/app/kibana#/dashboard/208ff0e0-8d38-11e9-8837-673c4c48f13a?embed=true&_g=(filters:!(),refreshInterval:(pause:!f,value:30000),time:(from:now%2Fd,to:now%2Fd))&_a=(description:'Total+agent+actions+and+activity+timeline',filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'1555cf40-856f-11e9-8837-673c4c48f13a',key:ownerID.keyword,negate:!f,params:(query:'8897ae30-973b-4432-a31b-66dbf7dfff0a'),type:phrase,value:'8897ae30-973b-4432-a31b-66dbf7dfff0a'),query:(match:(ownerID.keyword:(query:'8897ae30-973b-4432-a31b-66dbf7dfff0a',type:phrase))))),fullScreenMode:!f,options:(darkTheme:!f,hidePanelTitles:!f,useMargins:!t),panels:!((embeddableConfig:(),gridData:(h:20,i:'1',w:48,x:0,y:0),id:d53fe7b0-860e-11e9-ad4b-950856084762,panelIndex:'1',type:visualization,version:'7.3.0'),(embeddableConfig:(),gridData:(h:15,i:'3',w:48,x:0,y:20),id:f208db40-8c80-11e9-8837-673c4c48f13a,panelIndex:'3',type:visualization,version:'7.3.0')),query:(language:lucene,query:''),timeRestore:!t,title:'Erick+Guigui+-+1',viewMode:view)`},
            {name: '', topMargin:'50px', title:'Agent with Most Recent Sold Leads', type: 'url', delay: 10000, url: `https://guigui.sonicgraphs.com:5601/app/kibana#/dashboard/02bfb040-8d39-11e9-ad4b-950856084762?embed=true&_g=(filters:!(),refreshInterval:(pause:!f,value:30000),time:(from:now-7d,to:now))&_a=(description:'Agent+with+most+recent+sold+leads',filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'1555cf40-856f-11e9-8837-673c4c48f13a',key:ownerID.keyword,negate:!f,params:(query:'8897ae30-973b-4432-a31b-66dbf7dfff0a',type:phrase),type:phrase,value:'8897ae30-973b-4432-a31b-66dbf7dfff0a'),query:(match:(ownerID.keyword:(query:'8897ae30-973b-4432-a31b-66dbf7dfff0a',type:phrase))))),fullScreenMode:!f,options:(darkTheme:!f,hidePanelTitles:!f,useMargins:!t),panels:!((embeddableConfig:(),gridData:(h:20,i:'1',w:48,x:0,y:0),id:'73467d20-8c81-11e9-8837-673c4c48f13a',panelIndex:'1',title:'TOTAL+SOLD+LEADS+LAST+7+DAYS',type:visualization,version:'7.3.0'),(embeddableConfig:(),gridData:(h:15,i:'2',w:48,x:0,y:20),id:f208db40-8c80-11e9-8837-673c4c48f13a,panelIndex:'2',type:visualization,version:'7.3.0')),query:(language:lucene,query:''),timeRestore:!t,title:'Erick+Guigui+-+2',viewMode:view)`},
            {name: '', topMargin:'50px', title:'Best Lead Vendors', delay: 10000, type: 'url', url: `https://guigui.sonicgraphs.com:5601/app/kibana#/dashboard/90c93af0-8d39-11e9-ad4b-950856084762?embed=true&_g=(filters:!(),refreshInterval:(pause:!f,value:30000),time:(from:now-24h,to:now))&_a=(description:'Best+lead+vendors',filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'1555cf40-856f-11e9-8837-673c4c48f13a',key:ownerID.keyword,negate:!f,params:(query:'8897ae30-973b-4432-a31b-66dbf7dfff0a',type:phrase),type:phrase,value:'8897ae30-973b-4432-a31b-66dbf7dfff0a'),query:(match:(ownerID.keyword:(query:'8897ae30-973b-4432-a31b-66dbf7dfff0a',type:phrase))))),fullScreenMode:!f,options:(darkTheme:!f,hidePanelTitles:!f,useMargins:!t),panels:!((embeddableConfig:(),gridData:(h:20,i:'1',w:24,x:0,y:0),id:df5337b0-8c86-11e9-ad4b-950856084762,panelIndex:'1',type:visualization,version:'7.3.0'),(embeddableConfig:(vis:(legendOpen:!f)),gridData:(h:20,i:'2',w:24,x:24,y:0),id:'073bb930-8c8e-11e9-ad4b-950856084762',panelIndex:'2',type:visualization,version:'7.3.0'),(embeddableConfig:(),gridData:(h:15,i:'3',w:48,x:0,y:20),id:f208db40-8c80-11e9-8837-673c4c48f13a,panelIndex:'3',type:visualization,version:'7.3.0')),query:(language:lucene,query:''),timeRestore:!t,title:'Erick+Guigui+-+3',viewMode:view)`},
            {name: '', topMargin:'50px', title:'Most Recent Sold', delay: 10000, type: 'url', url: `https://guigui.sonicgraphs.com:5601/app/kibana#/dashboard/7f79a950-8d3a-11e9-ad4b-950856084762?embed=true&_g=(filters:!(),refreshInterval:(pause:!f,value:30000),time:(from:now-24h,to:now))&_a=(description:'Last+sold+state,+zip+code+and+vendor',filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'1555cf40-856f-11e9-8837-673c4c48f13a',key:ownerID.keyword,negate:!f,params:(query:'8897ae30-973b-4432-a31b-66dbf7dfff0a',type:phrase),type:phrase,value:'8897ae30-973b-4432-a31b-66dbf7dfff0a'),query:(match:(ownerID.keyword:(query:'8897ae30-973b-4432-a31b-66dbf7dfff0a',type:phrase))))),fullScreenMode:!f,options:(darkTheme:!f,hidePanelTitles:!f,useMargins:!t),panels:!((embeddableConfig:(),gridData:(h:8,i:'1',w:8,x:0,y:0),id:'5a86d120-8c83-11e9-ad4b-950856084762',panelIndex:'1',title:'LAST+SOLD+STATE',type:visualization,version:'7.3.0'),(embeddableConfig:(),gridData:(h:8,i:'2',w:14,x:8,y:0),id:'0bbd23a0-8c83-11e9-8837-673c4c48f13a',panelIndex:'2',title:'LAST+SOLD+ZIP+CODE',type:visualization,version:'7.3.0'),(embeddableConfig:(),gridData:(h:12,i:'3',w:22,x:0,y:8),id:e02f0a00-8c8c-11e9-8837-673c4c48f13a,panelIndex:'3',type:visualization,version:'7.3.0'),(embeddableConfig:(mapCenter:!(35.889050079360935,-98.21777343750001),mapZoom:4),gridData:(h:20,i:'4',w:26,x:22,y:0),id:'7dc80b50-8c82-11e9-8837-673c4c48f13a',panelIndex:'4',title:'SOLD+STATES+LAST+24+HOURS',type:visualization,version:'7.3.0'),(embeddableConfig:(),gridData:(h:15,i:'5',w:48,x:0,y:20),id:f208db40-8c80-11e9-8837-673c4c48f13a,panelIndex:'5',type:visualization,version:'7.3.0')),query:(language:lucene,query:''),timeRestore:!t,title:'Erick+Guigui+-+4',viewMode:view)`},
          {name: 'FTAThisWeekStandings.json', topMargin:'100px', title:'FTA Standings', delay: 8000, type: 'data'},
          {name: 'FSLThisWeekStandings.json', topMargin:'100px', title:'FSL Standings', delay: 8000, type: 'data'},
        //  {name: 'SatThisWeekStandings.json', topMargin:'100px', title:'Satellite Standings', delay: 8000, type: 'data'},
          {name: 'DivThisWeekStandings.json', topMargin:'100px', title:'Divisional Standings', delay: 8000, type: 'data'},
          {name: 'RegThisWeekStandings.json', topMargin:'100px', title:'Regional Standings', delay: 8000, type: 'data'},
          {name: 'PPThisWeekStandings.json', topMargin:'100px', title:'USHA Production Standings', delay: 8000, type: 'data'},
         // {name: 'milestones.jpg', topMargin:'100px', url:'https://s3.amazonaws.com/data.sonicwebdev.com/ushealth_orlando/milestones.jpg', title:'', delay: 8000, type: 'image'}
        ]},
      }
    }
  }
  componentDidMount() {
      console.log('componentDidMount')
  }
  
  
  render() {
    console.log(this.props)
    const { clients } = this.state
    const { ownerID } = this.props
   // const { match : { params :  { id } } } = this.props
    const { screens, logo } = clients[ownerID]
   // console.log(id)

    return ( 
      <SlideShow ownerID={ownerID} logoRight={logo} screens={screens} />
      )
  }
}




export default Powerboard;
