import React, { Component } from 'react';
import {Motion, spring} from 'react-motion';

class Fade extends Component {

  render() {
    const { show } = this.props

    return(
         <Motion style={{currentOpacity: spring(show ? 1 : 0, { stiffness: 140, damping: 20 })}}>
            {({currentOpacity}) =>
                <div style={{opacity: currentOpacity}}>
                    {this.props.children}
                </div>
            }
        </Motion>
    )
  }
}

export default Fade;
