import React, { Component } from "react";
import ReactTable from "react-table";
import 'react-table/react-table.css'

class CustomDataTable extends Component {

  makeHeader = (items) => {

    return items.map((item, index) => {
        return   {
          Header: item,
          accessor: item
        }
    })

  }
  render() {
    const { data } = this.props

    if (data.length < 1){
        return null
    }        
        const formattedData = data.map(item => {
          const newItem = {}
          for (var key in item) {
            newItem[key.replace(/[\W_]+/g," ")] = item[key]
          }
          return newItem
        })

        let columns = Object.keys(formattedData[0])

        console.log(formattedData)

        return (<ReactTable
          data={formattedData}
          defaultPageSize={20}
          showPagination={false}
          columns={this.makeHeader(columns)}
          getTdProps={() => ({
            style: {
            textAlign: 'center'
            }
            })}
        />
    );
  }
}

export default CustomDataTable