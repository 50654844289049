import React, { Component } from 'react';
import Amplify, { Storage } from 'aws-amplify';
import awsmobile from './aws-exports';
import { withAuthenticator } from 'aws-amplify-react';
import Front from './Front'

Amplify.configure(awsmobile);

Storage.configure({ level: 'private' });
let timer = null
class App extends Component {

  componentDidMount() {
    timer = setTimeout(() => window.location.reload(), 86400000)
  }
  componentWillUnmount() {
    clearTimeout(timer);
  }
  render() {
    return (
  <Front />
    );
  }
}

export default withAuthenticator(App, {
  includeGreetings: false, 
});
